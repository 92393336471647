import React from 'react';
export default function FourOhFourPage() {
  return (
    <>
      <div>
        <p>That page doesn't exist</p>;
      </div>
    </>
  );
}
